import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/login.vue'),
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('../pages/agreement.vue'),
  },
  {
    path: '/contract',
    name: 'Contract',
    component: () => import('../pages/contract.vue'),
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../pages/upload.vue'),
  },
  {
    path: '/sign',
    name: 'Sign',
    component: () => import('../pages/sign.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../pages/search.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;

  // 开发状态关闭
  const { isLogin } = store.state.user;
  if (process.env.NODE_ENV !== 'development') {
    if (to.name !== 'Login' && !isLogin) {
      router.replace('/login');
    }
  }
  next();
});

export default router;
