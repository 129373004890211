import { createApp } from 'vue';
import { Toast } from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
import api from './utils/httpApi';

const app = createApp(App);
/**
 * 通用函数
 */
app.config.globalProperties.$api = api;

/** 修改toast默认配置 */
Toast.setDefaultOptions({ duration: 2000, forbidClick: true });
Toast.setDefaultOptions('loading', { forbidClick: true });

app.use(store).use(router).use(Toast).mount('#app');
