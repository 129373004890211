import { PostJSON } from './http';

const getVerificationCode = (form = {}) => PostJSON('/messenger/stockAgreement/getVerificationCode.json', { ...form });
const login = (form = {}) => PostJSON('/messenger/stockAgreement/login.json', { ...form });
const getStockInfo = (form = {}) => PostJSON('/messenger/stockAgreement/getStockInfo.json', { ...form });
const submitStockInfo = (form = {}) => PostJSON('/messenger/stockAgreement/submitStockInfo.json', { ...form });
const getImage = (form = {}) => PostJSON('/messenger/stockAgreement/getImage.json', { ...form });
const getResult = (form = {}) => PostJSON('/messenger/stockAgreement/getResult.json', { ...form });
const getStockAgreementInfo = (form = {}) => PostJSON('/messenger/stockAgreement/getStockAgreementInfo.json', { ...form });
const updateImage = (form = {}) => PostJSON('/messenger/stockAgreement/updateImage.json', form, { title: '上传视频' });

export default {
  getVerificationCode,
  login,
  getStockInfo,
  submitStockInfo,
  getImage,
  getResult,
  getStockAgreementInfo,
  updateImage,
};
