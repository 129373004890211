export default {
  namespace: true,
  state: {
    isLogin: false,
  },
  mutations: {
    setIsLogin(state, { value, cb }) {
      state.isLogin = value;
      if (typeof cb === 'function') {
        cb();
      }
    },
    loginOut(state) {
      state.isLogin = false;
    },
  },
  actions: {
    handleLogin(ctx, cb) {
      ctx.commit('setIsLogin', { value: true, cb });
    },
  },
};
